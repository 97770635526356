exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mix-and-match-tsx": () => import("./../../../src/pages/mix-and-match.tsx" /* webpackChunkName: "component---src-pages-mix-and-match-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-templates-collection-template-tsx": () => import("./../../../src/templates/collectionTemplate.tsx" /* webpackChunkName: "component---src-templates-collection-template-tsx" */),
  "component---src-templates-contact-template-tsx": () => import("./../../../src/templates/contactTemplate.tsx" /* webpackChunkName: "component---src-templates-contact-template-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/templates/productTemplate.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */),
  "component---src-templates-promotional-products-template-tsx": () => import("./../../../src/templates/promotionalProductsTemplate.tsx" /* webpackChunkName: "component---src-templates-promotional-products-template-tsx" */),
  "component---src-templates-range-template-tsx": () => import("./../../../src/templates/rangeTemplate.tsx" /* webpackChunkName: "component---src-templates-range-template-tsx" */)
}

